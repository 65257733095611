import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import { NavLink } from 'react-router-dom'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

export default (props) => (
  <ListItem button component={NavLink}
            activeClassName="gray"
            exact={props.exact}
            to={props.route}>
    <ListItemIcon>
      {props.icon}
    </ListItemIcon>
    <ListItemText primary={props.text}/>
  </ListItem>
)