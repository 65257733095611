import React, {Component, Fragment} from 'react'
import ListSubheader from '@material-ui/core/ListSubheader'
import getIcon from '../utils/getIcon'
import NavListItem from './NavListItem'
import { navItemList, publishedListItems } from '../constants/mainNavItems'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'

// TODO consider using Hooks and withRouter to get the state for the NavLinks instead
// https://reactjs.org/docs/hooks-intro.html
class MainNavItems extends Component {
  generateItems(list) {
    return list.map((el, i) => (<NavListItem key={i} exact={el.exact} route={el.route} text={el.name} icon={getIcon(el.icon_name)}/>))
  }
  render() {
    return (
      <Fragment>
        <Divider/>
        <List>
          {this.generateItems(navItemList)}
        </List>
        {/*<Divider/>*/}
        {/*<List>*/}
          {/*<ListSubheader inset>Published Schedules</ListSubheader>*/}
          {/*{this.generateItems(publishedListItems)}*/}
        {/*</List>*/}
      </Fragment>
    )
  }
}

export default MainNavItems

