import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'
import Collapse from '@material-ui/core/es/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'
import NavListItem from './NavListItem'
import * as ROUTES from '../constants/routes'
import getIcon from '../utils/getIcon'
import * as ICONS from '../constants/iconNames'
import Divider from '@material-ui/core/es/Divider/Divider'

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
})

class ManagerNavList extends Component {
  state = {
    scheduler_open: false,
    approval_open: false,
    manager_open: false
  }
  handleSchedulerClick = () => {
    this.setState(state => ({ scheduler_open: !state.scheduler_open }))
  }
  handleApprovalClick = () => {
    this.setState(state => ({ approval_open: !state.approval_open }))
  }
  handleManagerClick = () => {
    this.setState(state => ({ manager_open: !state.manager_open }))
  }

  render() {
    const { classes } = this.props
    return (
      <Fragment>
        <List
          component="nav"
          className={classes.root}
        >
          <ListSubheader inset>Scheduling</ListSubheader>
          <ListItem button onClick={this.handleSchedulerClick}>
            <ListItemIcon>
              <InboxIcon/>
            </ListItemIcon>
            <ListItemText inset primary="Scheduler"/>
            {this.state.scheduler_open ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={this.state.scheduler_open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Grains"/>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Broad"/>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Crows"/>
              </ListItem>
            </List>
          </Collapse>
          <NavListItem route={ROUTES.HOME} text='Weekly Reports' icon={getIcon(ICONS.BAR_CHART)}/>
          <NavListItem route={ROUTES.HOME} text='Custom Reports' icon={getIcon(ICONS.BAR_CHART)}/>
          <Divider/>
          <ListSubheader inset>Approvals</ListSubheader>
          <NavListItem route={ROUTES.HOME} text='All Availability' icon={getIcon(ICONS.QUERY_BUILDER)}/>
          <NavListItem route={ROUTES.HOME} text='All Time-Off' icon={getIcon(ICONS.SNOOZE)}/>
          <ListItem button onClick={this.handleApprovalClick}>
            <ListItemIcon>
              <InboxIcon/>
            </ListItemIcon>
            <ListItemText inset primary="By Location"/>
            {this.state.approval_open ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={this.state.approval_open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Grains"/>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Broad"/>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Crows"/>
              </ListItem>
            </List>
          </Collapse>
          <Divider/>
          <ListSubheader inset>Manager Tools</ListSubheader>
          <ListItem button onClick={this.handleManagerClick}>
            <ListItemIcon>
              <InboxIcon/>
            </ListItemIcon>
            <ListItemText inset primary="Manage"/>
            {this.state.manager_open ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={this.state.manager_open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Grains"/>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Broad"/>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <StarBorder/>
                </ListItemIcon>
                <ListItemText inset primary="Crows"/>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Fragment>
    )
  }
}


export default withStyles(styles)(ManagerNavList)