import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const Home = props => (
    <div>
        <h1>Home</h1>

        <p><button onClick={() => props.changePage()}>Go to about page via redux</button></p>
      {/*if a manager is logged in, show one thing*/}
      {/*if an employee is logged in, show another thing */}
    </div>
)

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    changePage: () => push('/app/about-us')
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)