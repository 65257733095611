import React from 'react'
import * as ICONS from '../constants/iconNames'
import DashboardIcon from '@material-ui/icons/Dashboard'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import PeopleIcon from '@material-ui/icons/People'
import HomeIcon from '@material-ui/icons/Home'
import SnoozeIcon from '@material-ui/icons/Snooze'
import StoreIcon from '@material-ui/icons/Store'
import WorkIcon from '@material-ui/icons/WorkOutline'
import GroupIcon from '@material-ui/icons/Group'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
// import NoteAddIcon from '@material-ui/icons/NoteAdd'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import BarChartIcon from '@material-ui/icons/BarChart'
// import WeekendIcon from '@material-ui/icons/Weekend'
import EventIcon from '@material-ui/icons/Event'
import DateRangeIcon from '@material-ui/icons/DateRange'

const getIcon = (name) => {
  switch (name) {
    case ICONS.DASHBOARD:
      return <DashboardIcon/>
    case ICONS.HOME:
      return <HomeIcon/>
    case ICONS.CALENDAR_TODAY:
      return <CalendarTodayIcon/>
    case ICONS.QUERY_BUILDER:
      return <QueryBuilderIcon/>
    case ICONS.EVENT:
      return <EventIcon/>
    case ICONS.SNOOZE:
      return <SnoozeIcon/>
    case ICONS.DATE_RANGE:
      return <DateRangeIcon/>
    case ICONS.BAR_CHART:
      return <BarChartIcon/>
    case ICONS.PERSON:
      return <PeopleIcon/>
    case ICONS.PERSON_ADD:
      return <PersonAddIcon/>
    case ICONS.STORE:
      return <StoreIcon/>
    case ICONS.WORK:
      return <WorkIcon/>
    case ICONS.GROUP:
      return <GroupIcon/>
    default:
      return null
  }
}

export default getIcon