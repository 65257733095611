import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { withFirebase } from '../components/Firebase'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import provinces from '../constants/provinces'

// TODO use themes
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  formControl: {
    minWidth: '100%'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  },
  headerButton: {
    float: 'right'
  }
})

class Location extends Component {
  constructor(props) {
    super(props)
    this.classes = this.props.classes
    this.firebase = this.props.firebase

    this.state = {
      location: {
        name: '',
        store_code: '',
        province: '',
        city: '',
        published_to: '',
        shift_length: ''
      },
      readOnly: true,
      labelWidth: 71,
      locationId: this.props.match.params.locationId
    }
  }

  componentWillMount() {
    this.handleReset()
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    })
  }

  toggleEdit = () => {
    this.setState({ readOnly: !this.state.readOnly })
  }

  handleReset = () => {
    this.firebase.getLocationById(this.props.match.params.locationId).then(snap => {
      const location = snap.val()
      console.log('loaded: ', location)
      if (!location.province) {
        location.province = ''
      }
      if (!location.store_code) {
        location.store_code = ''
      }
      if (!location.published_to) {
        location.published_to = ''
      }

      this.setState({ location: { ...location }, locationId: this.props.match.params.locationId })
    })
  }

  handleChangeInput = (e) => {
    const event = { ...e }
    this.setState(prevState => ({
      location: {
        ...prevState.location,
        [event.target.name]: event.target.value
      }
    }))
  }

  handleUpdate = () => {
    console.log('updating: ', this.state.location)
    this.firebase.updateLocationDetails(this.state.locationId, this.state.location).then(snap => {
      console.log('wrote: ', snap)
    })
    this.setState({ readOnly: true })
  }

  render() {
    console.log('location: ', this.state.location)
    return (
      <>
        <Paper className={this.classes.paper}>
          <Typography component="h1" variant="h4"
                      align="left">
            View {this.state.location.name}
            <Button variant="contained"
                    color="primary"
                    onClick={this.toggleEdit}
                    className={this.classes.headerButton}>
              {this.state.readOnly ? 'Edit Location' : 'Read Only'}
            </Button>
          </Typography>
        </Paper>
        <Paper className={this.classes.paper}>
          <Typography component="h2" variant="h4"
                      align="left">
            Details
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                value={this.state.location.name}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="name"
              />
            </Grid>
            <Grid item xs>
              <TextField
                id="storeCode"
                name="store_code"
                label="Store Code"
                fullWidth
                value={this.state.location.store_code}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="store_code"
              />
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item sm>
              <TextField
                required
                id="shiftLength"
                name="shift_length"
                label="Shift Length"
                fullWidth
                type="number"
                value={this.state.location.shift_length}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
              />
            </Grid>
            <Grid item sm>
              <TextField
                required
                id="publishTo"
                name="publised_to"
                label="Sked Published To"
                fullWidth
                value={this.state.location.published_to}
                disabled={true}
              />
            </Grid>
            <Grid item sm>
              <FormControl variant="outlined" className={this.classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Province
                </InputLabel>
                <Select
                  value={this.state.location.province}
                  onChange={this.handleChangeInput}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="province"
                      id="outlined-age-simple"
                    />
                  }
                  disabled={this.state.readOnly}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {provinces.map((name, i) => <MenuItem key={i} value={name}>{name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className={this.classes.buttons}>
            <Button onClick={this.handleReset} className={this.classes.button}>
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUpdate}
              className={this.classes.button}
              disabled={this.state.readOnly}
            > Update
            </Button>
          </div>
        </Paper>
      </>
    )
  }
}

const LocationWrap = compose(
  withFirebase
)(Location)


export default withStyles(styles)(LocationWrap)