import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import * as ROUTES from '../constants/routes'
import Home from './Home'
import About from '../components/About'
import Employees from './Employees'
import Employee from './Employee'
import Locations from './Locations'
import Location from './Location'
import Companies from './Companies'
import Company from './Company'

const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto'
  },
  appBarSpacer: theme.mixins.toolbar
})

class Content extends Component {
  render() {
    const { classes } = this.props
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <main>
          <Switch>
            <Route exact path={ROUTES.HOME} component={Home}/>
            <Route exact path={ROUTES.ABOUT_US} component={About}/>
            <Route exact path={ROUTES.COMPANIES} component={Companies}/>
            <Route exact path={ROUTES.COMPANY} component={Company}/>
            <Route exact path={ROUTES.EMPLOYEES} component={Employees}/>
            <Route exact path={ROUTES.EMPLOYEE} component={Employee}/>
            <Route exact path={ROUTES.LOCATIONS} component={Locations}/>
            <Route exact path={ROUTES.LOCATION} component={Location}/>
          </Switch>
        </main>
      </main>
    )
  }
}

Content.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(Content)