import React, { Component } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import VirtualTable from '../components/VirtualTable'
import { withFirebase } from '../components/Firebase'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ROUTES from '../constants/routes'
import Typography from '@material-ui/core/Typography'
import reduce from 'lodash/reduce'

// TODO use themes
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
})

class Companies extends Component {
  constructor(props) {
    super(props)
    this.classes = this.props.classes
    this.firebase = this.props.firebase
    this.state = {
      data: []
    }

    this.columns = [
      {
        width: 120,
        flexGrow: 0.2,
        label: 'Name',
        dataKey: 'name',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.1,
        label: '# of Locations',
        dataKey: 'location_count',
        numeric: true
      },
      {
        width: 120,
        flexGrow: 0.2,
        label: '# of Employees',
        dataKey: 'employee_count',
        numeric: true
      },
      {
        width: 120,
        flexGrow: 0.1,
        label: 'Tier',
        dataKey: 'tier',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.2,
        label: 'ID',
        dataKey: 'id',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.2,
        label: 'Province',
        dataKey: 'province',
        numeric: false
      },
    ]
  }

  componentWillMount() {
    this.firebase.getAllCompanies().then(snap => {

      const results = snap.val()
      const blah = Object.keys(results).map(key => {
        results[key].id = key
        if(results[key].locations){
          results[key].location_count = Object.keys(results[key].locations).length
        }
        if(results[key].employees){
          results[key].employee_count = Object.keys(results[key].employees).length
        }
        return results[key]
      })
      this.setState({ data: blah })
    })
  }

  onRowClick = (event) => {
    this.props.onClickLocation(event.rowData.id)
  }

  render() {
    return <main>
      <Paper className={this.classes.paper}>
        <Typography component="h1" variant="h4" align="left">View Companies</Typography>
      </Paper>
      <Paper className={this.classes.root}>
        <VirtualTable data={this.state.data} columns={this.columns} sort={(name) => name}
                      onRowClick={this.onRowClick}/>
      </Paper>
    </main>
  }
}

const CompaniesWrap = compose(
  withFirebase
)(Companies)

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => bindActionCreators({
  onClickLocation: (id) => push([ROUTES.COMPANIES, id].join('/'))  // TODO still need to change pages
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompaniesWrap))