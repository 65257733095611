import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { withFirebase } from '../components/Firebase'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PowerSettingIcon from '@material-ui/icons/PowerSettingsNew'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MainNavItems  from '../components/MainNavItems'
import NestedNavList from '../components/ManagerNavList'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from "connected-react-router"
import * as ROUTES from '../constants/routes'

const drawerWidth = 240
const styles = theme => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  h5: {
    marginBottom: theme.spacing.unit * 2
  }
})

class Header extends Component {
  constructor(props) {
    super(props)
    this.classes = this.props.classes
    this.state = {
      drawer_open: true
    }
    this.firebase = props.firebase
    // this.signOut = this.signOut.bind(this)
  }

  handleDrawerOpen = () => {
    this.setState({ drawer_open: true })
  }
  handleDrawerClose = () => {
    this.setState({ drawer_open: false })
  }
  signOut =() => {
    this.firebase.signOut()
    this.props.leaveApp()
  }

  render() {
    return (
      <Fragment>
        <AppBar
          position="absolute"
          className={classNames(this.classes.appBar, this.state.drawer_open && this.classes.appBarShift)}>
          <Toolbar disableGutters={!this.state.drawer_open} className={this.classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(this.classes.menuButton, this.state.drawer_open && this.classes.menuButtonHidden)}>
              <MenuIcon/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={this.classes.title}>
              Sked Guru
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon/>
              </Badge>
            </IconButton>
            <IconButton color="inherit" onClick={this.signOut}>
              <PowerSettingIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(this.classes.drawerPaper, !this.state.drawer_open && this.classes.drawerPaperClose)
          }}
          open={this.state.drawer_open}>
          <div className={this.classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon/>
            </IconButton>
          </div>
          {/* obviously, this just screams to be refactored! */}
          <MainNavItems />
          {/*<Divider/>*/}
          {/*<NestedNavList />*/}
        </Drawer>
      </Fragment>
    )
  }
}

const HeaderWrap = compose(
  withFirebase
)(Header)

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => bindActionCreators({
  leaveApp: () => push(ROUTES.SIGN_IN)  // TODO still need to change pages
}, dispatch)

Header.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderWrap))