import React, { Fragment } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store/configureStore'
import App from './containers/App'
import Firebase, { FirebaseContext } from './components/Firebase'
import CssBaseLine from '@material-ui/core/CssBaseline';
import 'sanitize.css/sanitize.css'

render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <ConnectedRouter history={history}>
        <Fragment>
          <CssBaseLine />
          <App />
        </Fragment>
      </ConnectedRouter>
    </FirebaseContext.Provider>
  </Provider>,
  document.querySelector('#root')
)
