import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from './Header'
import Content from './Content'

const styles = (theme) => ({
  root: {
    display: 'flex'
  }
})

class Core extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Header/>
        <Content />
      </div>
    )
  }

}


export default withStyles(styles)(Core)