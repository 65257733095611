import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import LockIcon from '@material-ui/icons/LockOutlined'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import * as ROUTES from '../constants/routes'
import * as ACTIONS from '../actions/actionTypes'
import { compose } from 'recompose'
import { withFirebase } from './Firebase'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
})

const SignInPage = (props) => {
  const { classes } = props

  return (
    <main className={classes.main}>
      <CssBaseline/>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <SignInForm {...props} />
      </Paper>
    </main>
  )
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
}

class SignInForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
    this.firebase = props.firebase
    this.classes = props.classes
  }

  onSubmit = (event) => {
    const { email, password } = this.state
    // const self = this
    this.firebase
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.setState({ ...INITIAL_STATE })
        this.props.changePage()
        // this.props.onAuthenticated(user)
      })
      .catch(error => {
        this.setState({ error })
      })
    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <form className={this.classes.form} onSubmit={this.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input id="email" name="email" type="email" autoComplete="email" autoFocus onChange={this.onChange}/>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input id="password" name="password" type="password" autoComplete="current-password"
                 onChange={this.onChange}/>
        </FormControl>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary"/>}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={this.classes.submit}
          disabled={isInvalid}
        >
          Sign in
        </Button>

        {error && <p>{error.message}</p>}
      </form>

    )
  }
}

// attach Firebase
const SignInWrap = compose(
  withFirebase
)(SignInPage)

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => bindActionCreators({
  onAuthenticated: (user) => dispatch({type: ACTIONS.SIGN_IN, payload: user}),
  changePage: () => push(ROUTES.HOME)  // TODO still need to change pages
}, dispatch)

SignInPage.propTypes = {
  classes: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignInWrap))