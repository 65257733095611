import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { withFirebase } from '../components/Firebase'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import notifications from '../constants/supportedNotifications'
import Checkbox from '@material-ui/core/Checkbox'
import reduce from 'lodash/reduce'

// TODO use themes
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  formControl: {
    minWidth: '100%'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  },
  headerButton: {
    float: 'right'
  }
})

class Employee extends Component {
  constructor(props) {
    super(props)
    this.classes = this.props.classes
    this.firebase = this.props.firebase

    this.state = {
      employee: {
        first_name: '',
        last_name: '',
        employee_number: '',
        email: '',
        language: '',
        phone: '',
        calendar_path: '',
        notifications: this.fillNotificationPlaceholders(),
        employeeId: null
      },
      readOnly: true,
      labelWidth: 71,
      employee_id: this.props.match.params.employeeId
    }
  }

  fillNotificationPlaceholders = () => {
    return reduce(notifications, (result, value) => {
      if (value.email) {
        result[this.getNotificationPropName('email', value.propName)] = false
      }
      if (value.sms) {
        result[this.getNotificationPropName('sms', value.propName)] = false
      }
      if (value.push) {
        result[this.getNotificationPropName('push', value.propName)] = false
      }
      return result
    }, {})
  }

  getNotificationPropName = (type, name) => {
    return [type, name].join('_')
  }

  componentWillMount() {
    this.handleReset()
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    })
  }

  toggleEdit = () => {
    this.setState({ readOnly: !this.state.readOnly })
  }

  handleReset = () => {
    this.firebase.getEmployeeById(this.props.match.params.employeeId).then(snap => {
      const employee = snap.val()
      if (!employee.notifications) {
        employee.notifications = this.fillNotificationPlaceholders()
      }
      if (!employee.language) {
        employee.language = 'English'
      }
      if (!employee.phone) {
        employee.phone = ''
      }
      this.setState({ employee: { ...employee }, employee_id: this.props.match.params.employeeId })
    })
  }

  handleChangeCheck = (name) => event => {
    const e = { ...event }
    this.setState(prevState => ({
      employee: {
        ...prevState.employee,
        notifications: {
          ...prevState.employee.notifications,
          [e.target.value]: e.target.checked
        }
      }
    }))
  }

  handleChangeInput = (e) => {
    const event = { ...e }
    this.setState(prevState => ({
      employee: {
        ...prevState.employee,
        [event.target.name]: event.target.value
      }
    }))
  }

  handleUpdate = () => {
    this.firebase.updateEmployeeDetails(this.state.employee_id, this.state.employee).then(snap => {
      console.log('wrote: ', snap)
    })
    this.setState({ readOnly: true })
  }

  handleUpdateNotification = () => {
    this.firebase.updateEmployeeNotifications(this.state.employee_id, this.state.employee).then(snap => {
      console.log('wrote: ', snap)
    })
    this.setState({ readOnly: true })
  }

  renderNotificationGroup = (group) => {
    return reduce(notifications, (result, value, key) => {
      if (value[group]) {
        const propName = this.getNotificationPropName(group, value.propName)
        result.push(
          <FormControlLabel
            key={key}
            control={
              <Checkbox checked={this.state.employee.notifications[propName]}
                        onChange={this.handleChangeCheck(value.prop)}
                        value={propName} key={value.prop} disabled={this.state.readOnly}/>
            }
            label={value.label}
          />
        )
      }
      return result
    }, [])

  }

  render() {
    console.log('employee: ', this.state.employee)
    return (
      <>
        <Paper className={this.classes.paper}>
          <Typography component="h1" variant="h4"
                      align="left">
            View {this.state.employee.first_name} {this.state.employee.last_name}
            <Button variant="contained"
                    color="primary"
                    onClick={this.toggleEdit}
                    className={this.classes.headerButton}>
              {this.state.readOnly ? 'Edit Employee' : 'Read Only'}
            </Button>
          </Typography>
        </Paper>
        <Paper className={this.classes.paper}>
          <Typography component="h2" variant="h4"
                      align="left">
            Details
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs>
              <TextField
                required
                id="firstName"
                name="first_name"
                label="First name"
                fullWidth
                value={this.state.employee.first_name}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="fname"
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                id="lastName"
                name="last_name"
                label="Last name"
                fullWidth
                value={this.state.employee.last_name}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="lname"
              />
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item sm>
              <TextField
                id="email"
                name="email"
                label="Email"
                fullWidth
                value={this.state.employee.email}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="email"
              />
            </Grid>
            <Grid item sm>
              <TextField
                id="phone"
                name="phone"
                label="Phone #"
                fullWidth
                value={this.state.employee.phone}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="phone"
              />
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item sm>
              <TextField
                id="employeeNumber"
                name="employee_number"
                label="Employee Number"
                fullWidth
                value={this.state.employee.employee_number}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="empNum"
              />
            </Grid>
            <Grid item sm>
              <FormControl variant="outlined" className={this.classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Language
                </InputLabel>
                <Select
                  value={this.state.employee.language}
                  onChange={this.handleChangeInput}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="language"
                      id="outlined-age-simple"
                    />
                  }
                  disabled={this.state.readOnly}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'English'}>English</MenuItem>
                  <MenuItem value={'French'}>French</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs>
              <TextField
                required
                id="calendarPath"
                name="calendar_path"
                label="Calendar Path"
                fullWidth
                value={this.state.employee.calanedar_path}
                disabled={true}
              />
            </Grid>
          </Grid>
          <div className={this.classes.buttons}>
            <Button onClick={this.handleReset} className={this.classes.button}>
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUpdate}
              className={this.classes.button}
              disabled={this.state.readOnly}
            > Update
            </Button>
          </div>
        </Paper>
        <Paper className={this.classes.paper}>
          <Typography component="h2" variant="h4"
                      align="left">
            Notifications
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs>
              <FormControl component="fieldset" className={this.classes.formControl}>
                <FormLabel component="legend">Email</FormLabel>
                <FormGroup>
                  {this.renderNotificationGroup('email')}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl component="fieldset" className={this.classes.formControl}>
                <FormLabel component="legend">SMS</FormLabel>
                <FormGroup>
                  {this.renderNotificationGroup('sms')}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl component="fieldset" className={this.classes.formControl}>
                <FormLabel component="legend">Push Notification</FormLabel>
                <FormGroup>
                  {this.renderNotificationGroup('push')}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <div className={this.classes.buttons}>
            <Button onClick={this.handleReset} className={this.classes.button}>
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUpdateNotification}
              className={this.classes.button}
              disabled={this.state.readOnly}
            > Update
            </Button>
          </div>
        </Paper>
      </>
    )
  }
}

const EmployeeWrap = compose(
  withFirebase
)(Employee)


export default withStyles(styles)(EmployeeWrap)