import React, { Component } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import VirtualTable from '../components/VirtualTable'
import { withFirebase } from '../components/Firebase'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ROUTES from '../constants/routes'
import isNil from 'lodash/isNil'
import Typography from '@material-ui/core/Typography'

// TODO use themes
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
})

class Employees extends Component {
  constructor(props) {
    super(props)
    this.classes = this.props.classes
    this.firebase = this.props.firebase
    this.state = {
      data: []
    }

    this.columns = [
      {
        width: 120,
        flexGrow: 0.1,
        label: 'First Name',
        dataKey: 'first_name',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.1,
        label: 'Last Name',
        dataKey: 'last_name',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.1,
        label: 'Company',
        dataKey: 'company',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.2,
        label: 'Email',
        dataKey: 'email',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.08,
        label: 'Created At',
        dataKey: 'created_at',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.12,
        label: 'ID',
        dataKey: 'id',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.2,
        label: 'User ID',
        dataKey: 'uid',
        numeric: false
      },
      {
        width: 120,
        flexGrow: 0.1,
        label: 'Active',
        dataKey: 'active_text',
        numeric: false
      },
      {
        width: 120,
        label: 'Status',
        dataKey: 'status',
        numeric: false
      }
    ]
  }

  componentWillMount() {
    this.firebase.getAllEmployees().then(snap => {

      const results = snap.val()
      const blah = Object.keys(results).map(key => {
        results[key].id = key
        results[key].active_text = isNil(results[key].active) ? 'false' : results[key].active.toString()
        return results[key]
      })
      this.setState({ data: blah })
    })
  }

  onRowClick = (event) => {
    this.props.onClickEmployee(event.rowData.id)
  }

  render() {
    return <main>
      <Paper className={this.classes.paper}>
        <Typography component="h1" variant="h4" align="left">View Employees</Typography>
      </Paper>
      <Paper className={this.classes.root}>
        <VirtualTable data={this.state.data} columns={this.columns} sort={(name) => name}
                      onRowClick={this.onRowClick}/>
      </Paper>
    </main>
  }
}

const EmployeesWrap = compose(
  withFirebase
)(Employees)

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => bindActionCreators({
  onClickEmployee: (id) => push([ROUTES.EMPLOYEES, id].join('/'))  // TODO still need to change pages
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmployeesWrap))