import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router'
import SignIn from '../components/SignIn'
import Core from './Core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ROUTES from '../constants/routes'
import { compose } from 'recompose'
import { withFirebase } from '../components/Firebase'
import * as ACTIONS from '../actions/actionTypes'

class App extends Component {
  constructor(props) {
    super(props)
    this.firebase = this.props.firebase
  }

  componentDidMount() {
    // TODO check if authUser is there by looking at authStateChanged
    this.listener = this.firebase.onAuthStateChanged(authUser => {
      // set the state in Redux to share
      console.log('authUser: ', authUser)
      if (authUser) {
        // if this is different from what's in Redux, sign out
        // else you're in the right place, send them to home
        this.props.onAuthenticated()
      } else {
        // if there's no value, clear the value in redux
        this.props.signOut()
      }
    })
  }

  componentWillUnmount() {
    this.listener() // unsubscribe
  }

  render() {
    return (
      <Switch>
        <Route exact path={ROUTES.SIGN_IN} component={SignIn}/>
        <Route path={ROUTES.CORE} component={Core}/>
      </Switch>
    )
  }

}

const AppWrap = compose(
  withFirebase
)(App)
const mapStateToProps = (state) => ({
  authUser: state.authUser
})
const mapDispatchToProps = dispatch => bindActionCreators({
  onAuthenticated: () => dispatch({type: ACTIONS.SIGN_IN, payload: ''}),
  signOut: () => dispatch({ type: ACTIONS.SIGN_OUT }), // only thing I can see I'll do here is regularly check Firebase if I'm still using a valid user
}, dispatch)

// TODO don't use withRouter but use a better solution because https://github.com/ReactTraining/react-router/pull/5552#issuecomment-331502281
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWrap))