const notifications = [
  {
    label: 'Availability Approval',
    propName: 'availability_approval',
    email: true,
    sms: true,
    push: false
  },
  {
    label: 'Time-Off Approval',
    propName: 'timeoff_approval',
    email: true,
    sms: true,
    push: false
  },
  {
    label: 'Schedule Publishes',
    propName: 'schedule_published',
    email: true,
    sms: true,
    push: false
  },
  {
    label: 'General Broadcast',
    propName: 'general_broadcast',
    email: true,
    sms: true,
    push: false
  },{
    label: 'Trade Requests',
    propName: 'trade_request',
    email: true,
    sms: true,
    push: false
  },
  {
    label: 'Daily Digest',
    propName: 'daily_digest',
    email: true,
    sms: false,
    push: false
  },
]

export default notifications