import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {getTime} from 'date-fns'

const config = process.env.NODE_ENV === 'production' ? {
  apiKey: 'AIzaSyByE5_oX4nFQM5zUbT6oUJhNRFsj_u-fjc',
  authDomain: 'sked-guru-production.firebaseapp.com',
  databaseURL: 'https://sked-guru-production.firebaseio.com',
  projectId: 'sked-guru-production',
  storageBucket: 'sked-guru-production.appspot.com',
  messagingSenderId: '688479445225'
} : {
  apiKey: 'AIzaSyA7oU_MA7AXO5qWsT15ZEv7E9JHovglg1Y',
  authDomain: 'sked-guru-staging.firebaseapp.com',
  databaseURL: 'https://sked-guru-staging.firebaseio.com',
  projectId: 'sked-guru-staging',
  storageBucket: 'sked-guru-staging.appspot.com',
  messagingSenderId: '652481693571'
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.database = app.database()
  }

  createUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password)
  }
  signInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password)
  }
  signOut = () => this.auth.signOut()
  onAuthStateChanged = (callback) => this.auth.onAuthStateChanged(callback) // this may be a bad idea
  passwordReset = (email) => this.auth.sendPasswordResetEmail(email)
  passwordUpdate = (password) => this.auth.currentUser.updatePassword(password)

  getAllCompanies = () => this.database.ref('company').once('value')
  getCompanyById = (id) => this.database.ref('company').child(id).once('value')
  updateCompanyDetails = (companyId, company) =>
     this.database.ref('company').child(companyId).update({
      name: company.name,
      tier: company.tier,
      city: company.city || '',
      province: company.province || '',
      postal: company.postal || '',
      country: company.country || '',
      modified_by: 'admin',
      modified_at: getTime(new Date())
    })

  getAllEmployees = () => this.database.ref('employee').once('value')
  getEmployeeById = (id) => this.database.ref('employee').child(id).once('value')
  // TODO move these all into the server for better security control
  updateEmployeeDetails = (employeeId, employee) =>
    this.database.ref('employee').child(employeeId).update({
      first_name: employee.first_name,
      last_name: employee.last_name,
      phone: employee.phone || '',
      employee_number: employee.employee_number,
      email: employee.email,
      language: employee.language
    })
  updateEmployeeNotifications = (employee_id, employee) =>
    this.database.ref(['employee', employee_id].join('/')).child('notifications').update(employee.notifications)

  getAllLocations = () => this.database.ref('location').once('value')
  getLocationById = (id) => this.database.ref('location').child(id).once('value')
  updateLocationDetails = (locationId, location) =>
    this.database.ref('location').child(locationId).update({
      name: location.name,
      company: location.company,
      store_code: location.store_code || '',
      province: location.province || '',
      city: location.city || '',
      shift_length: parseFloat(location.shift_length)
    })
}

export default Firebase