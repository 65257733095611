import * as ROUTES from './routes'
import * as ICONS from './iconNames'

export const navItemList = [
  {
    name: 'Home',
    route: ROUTES.HOME,
    exact: true,
    icon_name: ICONS.HOME
  },
  {
    name: 'Companies',
    route: ROUTES.COMPANIES,
    exact: true,
    icon_name: ICONS.WORK
  },
  {
    name: 'Locations',
    route: ROUTES.LOCATIONS,
    exact: true,
    icon_name: ICONS.STORE
  },
  {
    name: 'Employees',
    route: ROUTES.EMPLOYEES,
    exact: true,
    icon_name: ICONS.GROUP
  }
]

export const publishedListItems = [
  {
    name: 'Daily Schedule',
    route: ROUTES.HOME,
    exact: true,
    icon_name: ICONS.EVENT
  },
  {
    name: 'Weekly Schedule',
    route: ROUTES.HOME,
    exact: true,
    icon_name: ICONS.DATE_RANGE
  }
]
