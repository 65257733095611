const provinces = [
  'Alberta',
  'British Columbia',
  'Saskatchewan',
  'Manitoba',
  'Ontario',
  'Nova Scotia',
  'Newfoundland',
  'New Brunswick',
  'Québec',
  'Prince Edward Island',
  'Yukon',
  'Northwest Territories',
  'Nunavut',
]

export default provinces