import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { withFirebase } from '../components/Firebase'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import provinces from '../constants/provinces'
import upperFirst from 'lodash/upperFirst'

// TODO use themes
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  formControl: {
    minWidth: '100%'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  },
  headerButton: {
    float: 'right'
  }
})

class Company extends Component {
  constructor(props) {
    super(props)
    this.classes = this.props.classes
    this.firebase = this.props.firebase

    this.state = {
      company: {
        name: '',
        locations: [],
        employees: [],
        positions: [],
        tier: '',
        city: '',
        province: '',
        postal: '',
        country: ''
      },
      readOnly: true,
      labelWidth: 71,
      companyId: this.props.match.params.companyId
    }
  }

  componentWillMount() {
    this.handleReset()
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    })
  }

  toggleEdit = () => {
    this.setState({ readOnly: !this.state.readOnly })
  }

  handleReset = () => {
    this.firebase.getCompanyById(this.props.match.params.companyId).then(snap => {
      const company = snap.val()
      console.log('loaded: ', company)
      if (!company.province) {
        company.province = ''
      }
      if (!company.city) {
        company.city = ''
      }
      if (!company.postal) {
        company.postal = ''
      }
      if (!company.country) {
        company.country = ''
      }
      if (!company.locations) {
        company.locations = []
      }
      if (!company.positions) {
        company.positions = []
      }
      if (!company.employees) {
        company.employees = []
      }
      this.setState({ company: { ...company }, companyId: this.props.match.params.companyId })
    })
  }

  handleChangeInput = (e) => {
    const event = { ...e }
    this.setState(prevState => ({
      company: {
        ...prevState.company,
        [event.target.name]: event.target.value
      }
    }))
  }

  handleUpdate = () => {
    console.log('updating: ', this.state.company)
    this.firebase.updateCompanyDetails(this.state.companyId, this.state.company).then(snap => {
      console.log('wrote: ', snap)
    })
    this.setState({ readOnly: true })
  }

  render() {
    console.log('company: ', this.state.company)
    return (
      <>
        <Paper className={this.classes.paper}>
          <Typography component="h1" variant="h4"
                      align="left">
            View {this.state.company.name}
            <Button variant="contained"
                    color="primary"
                    onClick={this.toggleEdit}
                    className={this.classes.headerButton}>
              {this.state.readOnly ? 'Edit Company' : 'Read Only'}
            </Button>
          </Typography>
        </Paper>
        <Paper className={this.classes.paper}>
          <Typography component="h2" variant="h4"
                      align="left">
            Details
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                value={this.state.company.name}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
                autoComplete="name"
              />
            </Grid>
            <Grid item xs>
              <FormControl variant="outlined" className={this.classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Tier
                </InputLabel>
                <Select
                  value={this.state.company.tier}
                  onChange={this.handleChangeInput}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="tier"
                      id="outlined-age-simple"
                    />
                  }
                  disabled={this.state.readOnly}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {['premier', 'basic'].map((name, i) => <MenuItem key={i} value={name}>{upperFirst(name)}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item sm>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                fullWidth
                value={this.state.company.city}
                onChange={this.handleChangeInput}
                disabled={this.state.readOnly}
              />
            </Grid>
            <Grid item sm>
              <TextField
                id="postalCode"
                name="postal"
                label="Postal Code"
                fullWidth
                value={this.state.company.postal}
                disabled={this.state.readOnly}
              />
            </Grid>
            <Grid item sm>
              <FormControl variant="outlined" className={this.classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Province
                </InputLabel>
                <Select
                  value={this.state.company.province}
                  onChange={this.handleChangeInput}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="province"
                      id="outlined-age-simple"
                    />
                  }
                  disabled={this.state.readOnly}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {provinces.map((name, i) => <MenuItem key={i} value={name}>{upperFirst(name)}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className={this.classes.buttons}>
            <Button onClick={this.handleReset} className={this.classes.button}>
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUpdate}
              className={this.classes.button}
              disabled={this.state.readOnly}
            > Update
            </Button>
          </div>
        </Paper>
      </>
    )
  }
}

const CompanyWrap = compose(
  withFirebase
)(Company)


export default withStyles(styles)(CompanyWrap)