export const DASHBOARD = 'DASHBOARD'
export const HOME = 'HOME'
export const CALENDAR_TODAY = 'CALENDAR_TODAY'
export const QUERY_BUILDER = 'QUERY_BUILDER'
export const SNOOZE = 'SNOOZE'
export const EVENT = 'EVENT'
export const DATE_RANGE = 'DATE_RANGE'
export const STAR_BORDER = 'STAR_BORDER'
export const PERSON = 'PERSON'
export const PERSON_ADD= 'PERSON_ADD'
export const BAR_CHART = 'BAR_CHART'
export const STORE = 'STORE'
export const WORK = 'WORK'
export const GROUP = 'GROUP'

